(function () {
    'use strict';

    angular.module('informaApp')
        .component('userList', {
            templateUrl: 'components/admin/user-list/template.html',
            controller: ['AdminUserService', 'debounce', '$q', UserList],
            bindings: {
                onReady: '<',
                users: '<',
                userCount: '<',
                userCompanies: '<'
            }
        });

    function UserList(AdminUserService, debounce, $q) {
        var vm = this;

        vm.dateFormat = 'MM/dd/yyyy';

        vm.search = '';

        vm.companyOptions = getCompanyOptions(vm);
        vm.filter = getFilters();
        vm.sortTo = {email: 'a - z'};
        vm.sortField = 'email';

        vm.activePage = 1;
        vm.itemsPerPage = 25;

        this.$onInit = function () {
            vm.ready = true;

            vm.filteredTo = getDefaultFilterValues(vm);
            vm.totalPages = Math.ceil(vm.userCount / vm.itemsPerPage);

            if (vm.onReady) {
                vm.onReady()
            }
        };

        vm.onSearchInputKeyUp = debounce(300, function () {
            vm.isSearching = true;

            vm.filterUsers(true).then(function () {
                vm.isSearching = false;
            });
        });

        vm.sortBy = function (prop) {
            highlightSorting(vm, prop);

            vm.filterUsers();
        };

        vm.editUser = function (user, e) {
            if (!user.editable) {
                vm.editUserRowIsShown = true;
                vm.editableUser = user;

                vm.users.forEach(function (user) {
                    user.editable = false;

                    if (vm.editingUser && vm.editingUser.id === user.id) {
                        vm.updateUser(user);
                    }
                });
                vm.editingUser = angular.copy(user);

                vm.moveEditUserRow(e.target);
            } else {
                vm.editUserRowIsShown = false;
                vm.updateUser(user);
            }

            user.editable = !user.editable;
        };

        vm.clearFilters = function () {
            vm.filteredTo = getDefaultFilterValues(vm);

            vm.filterUsers(true);
        };

        vm.onCompanyListReady = function (companyMultiSelect) {
            vm.companyMultiSelect = companyMultiSelect;

            if (vm.filteredTo && vm.filteredTo.company) {
                _.forEach(vm.filteredTo.company, function (x) {
                    vm.companyMultiSelect.multiselect('select', x, false);
                });
            }
        };

        vm.updateUser = function (user) {
            if (user) {
                for (var key in user) {
                    if (key !== 'editable' && user.hasOwnProperty(key)) {
                        user[key] = vm.editingUser[key];
                    }
                }
            }

            vm.editingUser = false;
        };

        vm.moveEditUserRow = moveEditUserRow;

        vm.filterUsers = function (updateAll, savePage, updatePages) {
            if (!savePage) {
                vm.activePage = 1;
            }

            cancelUserEditing(vm);

            var params = generateFilter(vm);

            if (updateAll) {
                var promises = [
                    vm.updateCompanyFilter(params.filter).then(vm.updateUsers),
                    vm.updateUserCount(params.filter)
                ];

                return $q.all(promises);
            } else {
                return updatePages
                    ? vm.updateUserCount(params.filter).then(vm.updateUsers)
                    : vm.updateUsers();
            }
        };

        vm.updateUsers = function() {
            var params = generateFilter(vm);

            return AdminUserService.getUsers(params)
                .then(function (users) {
                    vm.users = users;
                });
        };

        vm.saveUser = function (user) {
            AdminUserService.editUser(user.id, {isAdmin: user.admin})
                .then(function () {
                    vm.editUserRowIsShown = false;

                    user.editable = false;
                });
        };

        vm.onPageChange = function (page) {
            var offset = vm.itemsPerPage * (page - 1);
            vm.activePage = page;
            vm.filterUsers(false, true);
        };

        vm.onShowAllClick = function () {
            vm.showAllUsers = !vm.showAllUsers;
            vm.filterUsers(true);
        };

        vm.updateCompanyFilter = function (filter) {
            return AdminUserService.getCompanies(filter).then(function (companies) {
                vm.userCompanies = companies;

                var companyValues = companies.map(function (x) {
                    return x.value;
                });

                vm.filteredTo.company = vm.filteredTo.company.filter(function (x) {
                    return companyValues.indexOf(x) !== -1;
                });
            });
        };

        vm.updateUserCount = function (filter) {
            return AdminUserService.getUserCount(filter).then(function (count) {
                vm.userCount = count;
                vm.totalPages = Math.ceil(vm.userCount / vm.itemsPerPage);
            });
        };
    }

    function generateFilter(vm) {
        vm.itemsPerPage = vm.showAllUsers ? vm.userCount : 25;
        vm.totalPages = Math.ceil(vm.userCount / vm.itemsPerPage);

        return {
            limit: vm.itemsPerPage,
            page: vm.activePage - 1,
            filter: {
                search: vm.search ? vm.search : undefined,
                isAdmin: getValue(vm.filteredTo.admin.value),
                isActive: getValue(vm.filteredTo.active.value),
                contactMe: getValue(vm.filteredTo.contact_me.value),
                isTrial: getValue(vm.filteredTo.isTrial.value),
                company: vm.filteredTo.company.length ? vm.filteredTo.company : undefined
            },
            orderBy: vm.sortField,
            invertedOrder: vm.invertedOrder
        };
    }

    function getValue(value) {
        return value === 'all' ? undefined : value;
    }

    function cancelUserEditing(vm) {
        if (vm.editUserRowIsShown) {
            vm.editableUser.editable = false;
            vm.updateUser(vm.editableUser);
            vm.editUserRowIsShown = false;
        }
    }

    function moveEditUserRow(targetElement) {
        var editUserRow = $('.edit-user-row');
        var targetRow = $(targetElement).closest('tr');

        editUserRow.insertAfter(targetRow);

        var className = 'light-row';

        if (targetRow.hasClass(className)) {
            editUserRow.addClass(className);
        } else {
            editUserRow.removeClass(className);
        }
    }

    function highlightSorting(vm, prop) {
        var asc = 'a - z', desc = 'z - a';

        var oldValue = vm.sortTo[prop];

        vm.sortTo[vm.sortField] = null;
        vm.sortField = prop;

        vm.invertedOrder = oldValue === asc;
        vm.sortTo[prop] = vm.invertedOrder ? desc : asc;
    }

    function getDefaultFilterValues(vm) {
        if (vm.companyMultiSelect) {
            vm.companyMultiSelect.multiselect('deselectAll', false);
            vm.companyMultiSelect.multiselect('updateButtonText');
        }

        return {
            active: vm.filter.active[0],
            admin: vm.filter.admin[0],
            isTrial: vm.filter.isTrial[0],
            contact_me: vm.filter.contact_me[0],
            company: []
        };
    }

    function getFilters() {
        return {
            admin: getFilter('all', 'admin', 'user'),
            active: getFilter('all', 'active', 'inactive'),
            isTrial: getFilter('all', 'yes', 'no'),
            contact_me: getFilter('all', 'yes', 'no')
        };
    }

    function getCompanyOptions(vm) {
        return {
            orderBy: 'originalName',
            bind: function (x) {
                return x.originalName + ' (' + x.count + ')';
            },
            ifCondition: function (x) {
                return x.value !== 'all' && x.value !== '';
            },
            options: {
                allSelectedText: 'All Companies',
                nonSelectedText: 'All Companies',
                onChange: function (option, checked) {
                    var value = $(option).val();

                    if (checked) {
                        vm.filteredTo.company.push(value);
                    } else {
                        _.pull(vm.filteredTo.company, value);
                    }

                    vm.filterUsers(false, false, true);
                },
                onSelectAll: resetCompaniesFiler(vm),
                onDeselectAll: resetCompaniesFiler(vm)
            },
        };
    }

    function resetCompaniesFiler(vm, setAllUsers) {
        return function () {
            vm.filteredTo.company = [];

            if (setAllUsers) {
                vm.filteredTo.company = vm.userCompanies.map(function (x) {
                    return x.value;
                });
            }

            vm.filterUsers(true);
        };
    }

    function getFilter(allValue, trueValue, falseValue) {
        return [
            {text: allValue, value: 'all'},
            {text: trueValue, value: true},
            {text: falseValue, value: false}
        ];
    }
})();
